import LiveEvent from '../../components/live/live';
import Modal from '../../components/modal/modal';

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  const link = this;
  const modal = new Modal(this, {
    theme: link.dataset.theme ? link.dataset.theme : 'default',
    closerText: '',
    closeOnClickBg: false,
    onFormSuccess() {
      if (link.dataset.goal) {
        window.goal(link.dataset.goal);
      }
    },
  });
  return false;
});