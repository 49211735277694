import { onDomReady } from '../../components/dynamic/observer';

class Popup {
  constructor() {
    this.target = document.querySelector('.popup-link');
    if (this.target) {
      this.currentPosition = 0;
      // this.positionSetting = this.target ? this.target.dataset.popupScrollPosition : 0;
      this.timeoutSetting = this.target ? this.target.dataset.popupTimeout * 1000 : 0;
      // this.isPopupSee = sessionStorage.getItem('isPopupSee');
      this.secondsPassedFromLastActivity = 0;
      this.interval = null;
      this.bind();
    }
  }

  bind() {
    if (this.timeoutSetting !== 0) {
      this.initByTimeout();
    }
  }

  initByTimeout() {
    setTimeout(() => {
      this.initTimer();
    }, this.timeoutSetting);
  }

  openPopup() {
    if (this.target && !document.querySelector('body').classList.contains('modal-opened')) {
      this.target.click();
    }
  }

  initTimer() {
    clearInterval(this.interval);
    this.detectAnyAction();
    const instance = this;

    this.interval = setInterval(() => {
      instance.secondsPassedFromLastActivity++;
      if (instance.secondsPassedFromLastActivity > 2) {
        instance.openPopup();
        clearInterval(instance.interval);
      }
    }, 1000);
  }

  detectAnyAction() {
    document.addEventListener('click', (e) => {
      this.secondsPassedFromLastActivity = 0;
    });
  }
}

function initPopup() {
  onDomReady(() => {
    const popup = new Popup();
  });
}

document.addEventListener('DOMContentLoaded', initPopup);