const button = document.querySelector('[data-open-widget]');
const widgetContainer = document.querySelector('[data-widget-wrapper]');
const widget = document.querySelector('#mf_personal_widget_cont');

widgetContainer.addEventListener('click', (event) => {
  const element = event.target;
  if (element !== button && widget && !widget.contains(element)) {
    if (widgetContainer.classList.contains('_open')) {
      widgetContainer.classList.remove('_open');
    }
  }
});

button.addEventListener('click', () => {
  if (widgetContainer) {
    widgetContainer.classList.toggle('_open');
  }
});