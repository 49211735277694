<template>

    <div class="inputControl" :class="{
    'inputControl--error':error.value
  }">
        <input ref="input" type="tel" @focus="focus" :placeholder="placeholder" v-model="value" v-mask="'+7(###)###-##-##'">
        <span class="inputControl__error" v-if="error.value">{{ error.text }}</span>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";

const input = ref(null)

const props = defineProps({
    modelValue: {
        type: [String, Number],
        required: true
    },
    isNumber: {type: Boolean, default: false},
    error: {
        type: Object,
        default: {
            value: false,
            text: ''
        }
    },
    placeholder: {
        type: String,
        required: false
    }
})
const emit = defineEmits(['update:modelValue', 'changeInput'])
const value = computed({
    get: () => {
        return props.modelValue
    },
    set: (value) => {
        let string = value
        if (props.isNumber) {
            string = value.toString().replace(/[^0-9]/g, "");
            input.value.value = string
        }
        emit(`changeInput`, string)
        emit(`update:modelValue`, string)
    }
})
const error = computed({
    get: () => {
        return props.error
    },
    set: (value) => {
        emit(`update:error`, value)
    }
})
const focus = ()=>{
    emit(`update:error`, {value:false,text:'' })
    if (value.value.length===0){
        value.value='+7 (';
    }

}


</script>

<style scoped lang="scss">
.inputControl {
  input {
    transition: 0.3s ease all;
    height: 56px;
    border-radius: 12px;
    border: 2px solid #DFE2E3;
    padding: 16px 20px;
    width: 100%;
    color: #000000;
    background: #FFFFFF;

    &::placeholder {
      color: #7F7F94;
    }
      &:hover{
          border-color: #7F7F94;
      }
      &:focus{
          border-color: #393F67;
      }
  }
    &__error{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #E1002E;
    }

  &--error {
    input {
      border-color: #E1002E !important;
        &:focus{
            border-color: #E1002E !important;
        }
        &:hover{
            border-color: #E1002E !important;
        }
    }
  }

}


</style>