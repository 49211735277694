function initZoneImages() {
  const imageLinks = document.querySelectorAll('[data-zone-links] a');
  const imagesContainer = document.querySelector('[data-zone-images]');
  if (imagesContainer) {
    imageLinks.forEach((link, index) => {
      link.setAttribute('href', 'javascript:void(0)');
      link.dataset.zoneLink = index;
      link.addEventListener('click', (e) => {
        e.preventDefault();
      });
      const image = imagesContainer.querySelector(`[data-zone-image="${index}"]`);
      if (image) {
        const color = image.dataset.color;

        link.addEventListener('mouseenter', () => {
          if (!image.classList.contains('_active')) {
            const oldImage = imagesContainer.querySelector('[data-zone-image]._active');
            if (oldImage) {
              oldImage.classList.remove('_active');
              const oldIndex = oldImage.dataset.zoneImage;
              const oldLink = document.querySelector(`[data-zone-link="${oldIndex}"]`);
              if (oldLink) {
                oldLink.style.color = '';
              }
            }
            if (color) {
              link.style.color = color;
            }
            image.classList.add('_active');
          }
        });
      }
    });
  }
}

window.addEventListener('DOMContentLoaded', initZoneImages);