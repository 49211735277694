<template>
    <label :class="{
        'checked':isCheck
    }">
        <input hidden=""  type="radio" :value="value" :disabled="!active"  @change="$emit('update:modelValue',value)" :checked="isCheck">
        {{ title }}
    </label>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({

    modelValue: {
        type: Number,
        required: true
    },
    value: {
        type: Number,
        required: true,
    },

    title: {
        type: String,
        required: true,
    },
    active: {
        type: Boolean,
        default: true,
    },
    startActive: {
        type: [Number, Boolean],
        required: false,
        default: null
    },
    andActive: {
        type: [Number, Boolean],
        required: false,
        default: null
    }
})
const isCheck = computed(() => props.modelValue === props.value)


</script>

<style scoped>


</style>