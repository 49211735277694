<template>
  <label :class="{'agreeController--checked':isCheck}"  class="agreeController">
    <input :checked="modelValue" :true-value="true"  :false-value="false" @change="(event) =>$emit('update:modelValue',event.target.checked)" hidden="" type="checkbox">
    <svg class="svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect class="svg__rect" x="1" y="1" width="14" height="14" rx="4" stroke="#E73C5F" stroke-width="2"/>
      <rect class="svg__check" width="16" height="16" rx="5" fill="#E73C5F"/>

      <path class="svg__check" fill-rule="evenodd" clip-rule="evenodd"
            d="M11.2197 4.46975L6.49994 9.1895L4.78019 7.46975C4.48591 7.18552 4.01813 7.18959 3.72883 7.47889C3.43953 7.76819 3.43546 8.23597 3.71969 8.53025L5.96969 10.7803C6.26256 11.073 6.73731 11.073 7.03019 10.7803L12.2802 5.53025C12.5644 5.23597 12.5604 4.76819 12.2711 4.47889C11.9818 4.18959 11.514 4.18552 11.2197 4.46975Z"
            fill="white"/>
    </svg>
    <span v-html="content"></span>
  </label>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  modelValue: {
    default: false,
    type: Boolean,
  },
  content: {
    default: '',
    type: String,
  },
})
const on=true;
const off=false;
const isCheck = computed(() => {
  return props.modelValue
})

</script>

<style scoped lang="scss">
.agreeController {
  cursor: pointer;
  padding-left: 26px;
  position: relative;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  display: block;
  color: #7F7F94;



  >>>a {
    color: #E73C5F;
  }

  .svg {
    position: absolute;
    left: 0;
    top: 4.5px;

    &__check {
      transition: 0.3s ease all;
      opacity: 0;
    }
  }

  &--checked {
    .svg {
      &__check {
        opacity: 1;
      }
    }
  }
}

</style>