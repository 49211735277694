<template>
  <label :class="{'paymentControl--checked':isCheck}" class="paymentControl">
    <input hidden type="radio" :value="code" :checked="isCheck" @change="$emit('update:modelValue',code)">
    <span class="paymentControl__caption">
      <span class="paymentControl__logo" v-html="logo"></span>
      <span class="paymentControl__title">{{ title }}</span>
      <span :class="{'paymentControl__check--show':isCheck}" class="paymentControl__check">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M18.4395 4.9395L9 14.379L5.5605 10.9395C4.97193 10.371 4.03637 10.3792 3.45777 10.9578C2.87917 11.5364 2.87104 12.4719 3.4395 13.0605L7.9395 17.5605C8.52525 18.1461 9.47475 18.1461 10.0605 17.5605L20.5605 7.0605C21.129 6.47193 21.1208 5.53637 20.5422 4.95777C19.9636 4.37917 19.0281 4.37104 18.4395 4.9395Z"
      fill="#E73C5F"/>
</svg>

      </span>
    </span>
  </label>
</template>

<script setup>
import {computed, ref} from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  logo: {
    type: String,
    required: false,
    default: ''
  },
  code: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  }

})
const isCheck = computed(() => props.modelValue === props.code)


</script>

<style scoped lang="scss">
.paymentControl {
  position: relative;
  overflow: hidden;
  padding: 14px 20px;
  border: 2px solid #DFE2E3;
  cursor: pointer;
  width: 100%;
  background: #fff;
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  transition: 0.3s ease all;

  &--checked {
    background: #FDF0F3;
    border: 2px solid #E73C5F;
    color: #E73C5F;
  }

  &:hover {

    border: 2px solid #E73C5F;
  }

  &__caption {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__logo {
    display: flex;
  }

  &__check {
    transition: 0.3s ease all;
    position: absolute;
    display: flex;
    opacity: 0;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    &--show {
      opacity: 1;
    }
  }

  &__title {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .paymentControl {
    &__caption{
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }
  }
}


</style>