function initScrollImpacts() {
  // eslint-disable-next-line no-use-before-define
  const scrollImpact = new ScrollImpact();
}

class ScrollImpact {
  constructor() {
    this.groups = [];
    const targets = document.querySelectorAll('[data-impact-target]');
    targets.forEach((target) => {
      const key = target.dataset.impactTarget;
      const property = target.dataset.impactProperty;
      if (key) {
        const elements = document.querySelectorAll(`[data-impact-element="${key}"]`);
        if (elements) {
          this.groups[key] = {};
          this.groups[key].target = target;
          this.groups[key].elements = elements;
          this.groups[key].property = property;
        }
      }
    });
    this.lastCollect = new Date();
    this.scrollPositionIsChange = true;
    if (this.groups) {
      this.init();
    }
  }

  init() {
    let timeout = 0;
    if (process.env.NODE_ENV !== 'production') {
      timeout = 1500;
    }
    setTimeout(() => {
      this.handle();
    }, timeout);

    this.lastScrollPosition = null;
    window.requestAnimationFrame(() => this.handle());
  }

  handle() {
    this.now = new Date();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (this.lastScrollPosition === null || this.lastScrollPosition !== scrollPosition) {
      this.scrollPositionIsChange = true;
    }

    if ((this.now - this.lastCollect) > 500 && this.scrollPositionIsChange) {
      this.lastCollect = new Date();
      this.scrollPositionIsChange = false;

      this.eacher();
    }

    window.requestAnimationFrame(() => this.handle());
  }

  eacher() {
    const windowHeight = window.innerHeight;
    const scrolled = this.lastScrollPosition + windowHeight;

    for (const key in this.groups) {
      this.groups[key].elements.forEach((element) => {
        if (!element.dataset.impactActive) {
          this.checkElement(element, this.lastScrollPosition, scrolled);
        }
      });
    }
  }

  checkElement(element, scrollTop, scrollBottom) {
    const mode = element.dataset.impactMode || 'bottom';
    const topBorderPosition = element.getBoundingClientRect().top + scrollTop;
    const heightElement = element.offsetHeight;
    const ratio = element.dataset.impactRatio || 0;
    const bottomBorderPosition = Math.round(heightElement + topBorderPosition);

    if (bottomBorderPosition >= scrollBottom && (heightElement * ratio + topBorderPosition) < scrollBottom) {
      this.writeStyle(element);
    }
  }

  writeStyle(element) {
    const key = element.dataset.impactElement;
    const { target } = this.groups[key];
    const { property } = this.groups[key];
    target.style[property] = element.dataset.impactValue;
    const oldElement = document.querySelector(`[data-impact-element="${key}"][data-impact-active="true"]`);
    if (oldElement) {
      oldElement.dataset.impactActive = '';
    }
    element.dataset.impactActive = 'true';
  }
}

document.addEventListener('DOMContentLoaded', initScrollImpacts);