import gsap from 'gsap';

const slides = document.querySelectorAll('[data-slide]');
const sliderSwitches = document.querySelectorAll('[data-action]');

if (slides[0]) {
  slides[0].classList.add('floors__slide_active');
  sliderSwitches[0].querySelector('.floors__label').classList.add('floors__label_active');
}

const sliderResize = () => {
  for (const slide of slides) {
    if (slide.classList.contains('floors__slide_active')) {
      gsap.to('[data-sliderBlock]', { duration: 1.5, ease: "power4.out", height: slide.offsetHeight});
    }
  }
};

const sliderSwitchDelete = () => {
  for (const link of sliderSwitches) {
    if (link.querySelector('.floors__label').classList.contains('floors__label_active')) {
      link.querySelector('.floors__label').classList.remove('floors__label_active');
    }
  }
}


for (const sliderSwitch of sliderSwitches) {
  sliderSwitch.addEventListener('click', () => {
    sliderSwitchDelete();
    for (const slide of slides) {
      if (slide.classList.contains('floors__slide_active')) {
        slide.classList.remove('floors__slide_active');
      }
      if (slide.dataset.slide === sliderSwitch.dataset.action) {
        slide.classList.add('floors__slide_active');
        sliderSwitch.querySelector('.floors__label').classList.add('floors__label_active');
      }
    }
    sliderResize();
  });
}

const bind = () => {
  let timeout = 0;
  if (process.env.NODE_ENV !== 'production') {
    timeout = 2500;
  }
  setTimeout(() => {
    sliderResize();
  }, timeout);
}

bind();
window.addEventListener('resize', sliderResize);