/**
 *  Можно указать селектор к которому применится класс 'success' после успешной обработки формы
 *  По-умолчанию класс 'success' добавится непосредственно форме
 *
 *  data-success-selector=".success-holder"
 *
 *  <form action="{% url 'namespace:route' %}" method="post" data-ajax-form="ContactForm">
 *      ...
 *  </form>
 */

import { validatorValidateForm, validatorCleanErrors } from '../../components/forms/validation';
import LiveEvent from "../../components/live/live";
import Jax from "../../components/jax/jax";

new LiveEvent('click', '[href="tel:+78332444999"]', function clickPhone(e) {
    ym(57697594,'reachGoal','click_phone')
    ym(57697594,'reachGoal','all-phone-click')
    _tmr.push({ type: 'reachGoal', id: 3556635, goal: 'click_phone'});
})
new LiveEvent('click', '[href="https://vk.com/more_park_kirov"]', function clickPhone(e) {
    ym(57697594,'reachGoal','all-vk-click')
})

new LiveEvent('click', '[href="https://play.google.com/store/apps/details?id=com.itrack.kleverdevelopme219632&hl=ru&gl=US"]', function clickPhone(e) {
    ym(57697594,'reachGoal','google-play-click')
})
new LiveEvent('click', '[class="main-menu__lk"]', function clickPhone(e) {
    ym(57697594,'reachGoal','personal-acc-click')
})

new LiveEvent('click', '[href="https://apps.apple.com/ru/app/%D0%BC%D0%BE%D1%80%D0%B5-%D0%BF%D0%B0%D1%80%D0%BA/id1485701968"]', function clickPhone(e) {
    ym(57697594,'reachGoal','app-store-click')
})
new LiveEvent('click', '[href="mailto:sale@parkmore.ru"]', function clickPhone(e) {
    ym(57697594,'reachGoal','all-email-click')
})
new LiveEvent('submit', '[data-ajax-form]', function submitAjax(e) {
  e.preventDefault();
  switch (this.getAttribute('action')){
      case '/request/recall':
          ym(57697594,'reachGoal','all-lead-send');
          ym(57697594,'reachGoal','success_order_all')
          _tmr.push({ type: 'reachGoal', id: 3556635, goal: 'success_order_all'});
          break;
  }
  const classes = this.dataset.ajaxForm.split(',');
  const successSelector = this.dataset.successSelector;
  const success = successSelector ? document.querySelector(successSelector) : this;
  const successTrigger = this.dataset.successTrigger;

  const jax = new Jax(this.getAttribute('action'), this.getAttribute('method'), true);
  jax.send(new FormData(this)).then((data, xhr) => {
    let errorsList = {};
    if (data.errors) {
      errorsList = data.errors;
    }
    Object.keys(classes).forEach((i) => {
      const cls = classes[i];
      if (errorsList[cls]) {
        validatorValidateForm(this, cls, errorsList[cls]);
      } else {
        validatorCleanErrors(this, cls);
      }
    });
    if (data.state === 'success') {
      success.classList.add('success');
      if (successTrigger) {
        document.dispatchEvent(new CustomEvent('ajax-form-success', {'detail': {'form': this}}));
      }
      if (this.dataset.goal) {
        window.goal(this.dataset.goal);
      }
      setTimeout(() => {
        this.reset();
        document.dispatchEvent(new CustomEvent('success', {'detail': {'form': this}}));
        success.classList.remove('success');
      }, 3000);
    }
  })
});

/**
 Пример action:

 public function contact()
 {
     if (!$this->request->getIsAjax() || !$this->request->getIsPost()) {
            $this->error(404);
        }
        $form = new RequestForm();
        $data = [
            'state' => 'success'
        ];
        if (!($form->fill($_POST) && $form->valid && $form->send())) {
            $data = [
                'state' => 'error',
                'errors' => [
                    $form->classNameShort() => $form->getErrors()
                ]
            ];
        }
        echo json_encode($data);
 }

 */
