require('../scss/app.scss');

window.noZensmooth = true;

require('../fonts/TTNorms/css/TT.css');


require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

require('./common/true-vh.js');
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');
require('./image-index/image.js');
require('./common/need-animation.js');
require('./common/floating-field.js');

require('./field/phone.js');
require('../components/smart-tabs/smart-tabs');

require('./header/header');
require('./image-index/image.js');
require('./sliders/sliders');

require('./animation/parallax.js');
require('./animation/scrollImpact.js');
require('./animation/additional-accordion.js');
require('./animation/scrollmagic.js');

require('./widgets/widget.js');

require('./about/floors.js');
require('./about/teammates.js');
require('./about/teamDialog.js');

require('./popup/popup.js');

require('../vue/vue.js');
