<template>
  <TemplateBlock title="Ваши данные">
      <InputControl class="br12" placeholder="ФИО *"  v-model:error="errorName" v-model="userName">
      </InputControl>
  <!--      <InputControl class="br12" placeholder="Телефон *" v-model:error="errorPhone.value" v-model="phone">-->
<!--      </InputControl>-->
      <PhoneInput  class="br12" placeholder="Телефон *" v-model:error="errorPhone" v-model="phone"></PhoneInput>

      <InputControl class="br12" placeholder="E-mail *"  v-model:error="errorEmail" v-model="email">
      </InputControl>
  </TemplateBlock>
</template>
<script setup>

import InputControl from './controls/InputControl.vue' ;
import PhoneInput from './controls/PhoneInput.vue';
import TemplateBlock from "./TemplateBlock.vue";
import {useModelWrapper} from "../utils/modelWrapper";
const emit = defineEmits();
const props =defineProps({
  userName:{
    type:String,
    default:''
  },
  phone:{
    type:String,
    default:''
  },
  email:{
    type:String,
    default:''
  },
    errorName:{
        type:Object,
        default:{
            value: false,
            text: ''
        }
    },
    errorPhone:{
        type:Object,
        default:{
            value: false,
            text: ''
        }
    },
    errorEmail:{
        type:Object,
        default:{
            value: false,
            text: ''
        }
    },

})
const userName = useModelWrapper(props,emit,'userName')
const phone = useModelWrapper(props,emit,'phone')
const email = useModelWrapper(props,emit,'email')
const errorName = useModelWrapper(props,emit,'errorName')
const errorPhone = useModelWrapper(props,emit,'errorPhone')
const errorEmail = useModelWrapper(props,emit,'errorEmail')



</script>

<style scoped>

</style>