<template>
  <label class="selectButton" :class="{
      select:isCheck,
      disabled:!active,
  }">
      <input hidden="" type="radio" :value="value" :disabled="!active"  @change="$emit('update:modelValue',value)" :checked="isCheck">
 {{title}}
  </label>

</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    value: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    active: {
        type: Boolean,
        default: true,
    }

})
const isCheck = computed(() => props.modelValue === props.value)


</script>

<style scoped lang="scss">
.selectButton{
  transition: 0.3s ease all;
  cursor: pointer;
  border-radius: 8px;
  padding:  12px 2.9%;
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  //border:2px solid #fff;
  box-shadow: inset 0 0 0 2px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,&.select{
    color: #E73C5F;
    box-shadow: inset 0 0 0 2px #E73C5F;
  }
  &.disabled{
    background: #393F67;
    color:#7F7F94 ;
    box-shadow: inset 0 0 0 2px #393F67;
  }
}

</style>