class Accordion {
  constructor() {
    this.links = document.querySelectorAll('[data-accordion-item] [data-accordion-link]');
    this.items = document.querySelectorAll('[data-accordion-item]');
    this.blocks = [];
    this.bind();
  }

  bind() {
    this.links.forEach((link) => {
      link.addEventListener('click', this.handleClick.bind(this, link));
      this.blocks.push(link);
    });
  }

  handleClick(link) {
    const parent = link.closest('[data-accordion-item]');
    const target = link.nextElementSibling;
    if (parent.classList.contains('opened')) {
      this.closeTab(parent, target);
    } else {
      this.blocks.forEach((block) => {
        this.closeTab(block.closest('[data-accordion-item]'), block.nextElementSibling);
      });
      this.items.forEach((item) => {
        item.classList.remove('opened');
      });
      this.openTab(parent, target);
    }
  }

  closeTab(parent, target) {
    target.style.height = 0;
    parent.classList.remove('opened');
  }

  openTab(parent, target) {
    const article = target.children[0];
    const targetHeight = article.clientHeight;
    target.style.height = `${targetHeight}px`;
    parent.classList.add('opened');
  }
}

export default Accordion;