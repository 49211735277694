<template>
    <div :disabled="!active" class="guestRow">
        <div :class='{"guestRow__row--disabled":!active}' class="guestRow__row">
            <div class="guestRow__firstColumn">
                <div class="guestRow__title">{{title}}
                    <span v-if="toolTipText" @mouseenter="showTooltip=true" @mouseleave="showTooltip=false"
                          class="tooltipInfo">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 17.5C11.3542 17.5 12.6172 17.1549 13.7891 16.4648C14.9219 15.8138 15.8138 14.9219 16.4648 13.7891C17.1549 12.6172 17.5 11.3542 17.5 10C17.5 8.64583 17.1549 7.38281 16.4648 6.21094C15.8138 5.07812 14.9219 4.17969 13.7891 3.51562C12.6172 2.83854 11.3542 2.5 10 2.5C8.64584 2.5 7.38281 2.83854 6.21094 3.51562C5.07813 4.17969 4.17969 5.07812 3.51563 6.21094C2.83854 7.38281 2.5 8.64583 2.5 10C2.5 11.3542 2.83854 12.6172 3.51563 13.7891C4.17969 14.9219 5.07813 15.8138 6.21094 16.4648C7.38281 17.1549 8.64583 17.5 10 17.5ZM10 16.25C8.86719 16.25 7.8125 15.9635 6.83594 15.3906C5.89844 14.8437 5.15625 14.1016 4.60938 13.1641C4.03646 12.1875 3.75 11.1328 3.75 10C3.75 8.86719 4.03646 7.8125 4.60938 6.83594C5.15625 5.89844 5.89844 5.15625 6.83594 4.60937C7.8125 4.03646 8.86719 3.75 10 3.75C11.1328 3.75 12.1875 4.03646 13.1641 4.60937C14.1016 5.15625 14.8438 5.89844 15.3906 6.83594C15.9635 7.8125 16.25 8.86719 16.25 10C16.25 11.1328 15.9635 12.1875 15.3906 13.1641C14.8438 14.1016 14.1016 14.8437 13.1641 15.3906C12.1875 15.9635 11.1328 16.25 10 16.25ZM10.625 13.75L10.625 8.75L9.375 8.75L9.375 13.75L10.625 13.75ZM10.625 7.5L10.625 6.25L9.375 6.25L9.375 7.5L10.625 7.5Z"
                                fill="white"/>
                      </svg>
                        <ToolTip :show="showTooltip" :text="toolTipText"/>
                 </span>
                </div>
                <div class="guestRow__subtitle">{{subtitle}}</div>
            </div>
            <div class="guestRow__secondColumn">
                <CounterComponent :active="active" v-model="count"/>
            </div>
            <div v-if="useDr" class="guestRow__dr">
                День рождения
                <label class="checkBox" @mouseenter="showTooltipDr=true" @mouseleave="showTooltipDr=false" :class="{
                  'select':dr,
                  'disabled':!count
              }">
                    <input :disabled="!count"  hidden="" v-model="dr" type="checkbox">
                    <span class="checkBox__circle"></span>
                    <ToolTip :show="showTooltipDr"
                             :text="`Если день рождения сразу у нескольких человек – необходимо оформить разные заказы`"/>
                </label>
            </div>

        </div>
        <hr>
    </div>

</template>

<script setup>
import CounterComponent from "./CounterComponent.vue";
import {useModelWrapper} from "../../utils/modelWrapper";
import {computed, ref} from "vue";
import ToolTip from "./calendar/ToolTip.vue";

const emits = defineEmits();
const props = defineProps({
    count: {
        type: Number,
        default: 0
    },
    useDr: {
        type: Boolean,
        default: true
    },
    dr: {
        type: Boolean,
        default: false
    },
    active:{
        type: Boolean,
        default: true
    },
    title: {
        type: String,
        default: ''
    },
    toolTipText: {
        type: String,
        default: ''
    },
    subtitle: {
        type: String,
        default: ''
    }

})
const showTooltip = ref(false);
const showTooltipDr = ref(false);
const dr = useModelWrapper(props, emits, 'dr');
const count = useModelWrapper(props, emits, 'count');

// const checked=(event)=>{
//    dr.value=event.target.checked
// }
// const dr =
</script>
<style scoped lang="scss">


.guestRow {
  font-family: 'TT Norms';
    &__firstColumn{
        max-width:calc(100% - 135px)
    }
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
      align-items: center;
      position: relative;
      &--disabled{
          opacity: 0.2;
          cursor: not-allowed;
          &:after{
              content:"";
              bottom: -8px;
              top: -8px;
              left: -8px;
              right: -8px;
              border-radius: 12px;
              position: absolute;
              //background: rgba(255, 255, 255, 0.1);
          }
      }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7F7F94;
  }

  &__dr {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    background: #393F67;
    border-radius: 12px;

  }


}

.checkBox {
  position: relative;
  display: block;
  border: 2px solid rgba(255, 255, 255, 0.24);
  border-radius: 24px;
  width: 42px;
  height: 22px;
  position: relative;
  transition: 0.5s ease all;
  cursor: pointer;
    &.disabled{
        opacity: 0.5;
    }

  &__circle {
    position: absolute;
    transition: 0.5s ease all;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: #E73C5F;
    top: 50%;
    transform: translateY(-50%);
    left: 2px
  }

  &.select {
    border: 2px solid #E73C5F;
    background: #E73C5F;
    .checkBox {
      &__circle {
        background: #fff;
        left: auto;
        right: 2px;
      }
    }
  }

}

hr {
  border: none;
  border-bottom: 1px solid #393F67;
  margin-top: 16px;
  margin-bottom: 16px;
}

.tooltipInfo {
  position: relative;
  line-height: 20px;
  margin-left: 6.5px;
  display: inline-flex;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
    .guestRow{
        &__firstColumn{
            max-width:45%
        }
        &__title{
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 0;
        }
        &__dr{
            margin-top: 12px;
        }
    }
}
</style>