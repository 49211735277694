<template>
    <div class="container">
        <div class="timer">
            Время на оформление заказа: <span>{{ timerFormat }}</span>

        </div>
        <div class="ticketController">
            <TemplateCard title="Дата посещения">
                <TicketCalendar v-model="date"></TicketCalendar>
                <ul class="ticketController__info">
                    <li>Купить билет можно только <br>
                        на ближайшие 14 дней
                    </li>
                    <li>На текущий день купить билет нельзя</li>
                </ul>
            </TemplateCard>
            <TemplateCard title="Продолжительность и время">
                <div class="bodyCard">
                    <div class="wrapButtons wrapButtons--fullWidth">
                        <select-button v-for="tariff in tariffs" v-model="tariffValue"
                                       :value="tariff.value"
                                       :title="tariff.title"
                                       :active="tariff.active"
                        />
                    </div>
                    <hr>
                    <div class="wrapButtons">
                        <select-button v-for="period in periods" v-model="periodValue"
                                       :value="period.value"
                                       :title="period.title"
                                       :active="period.active"
                        />
                    </div>
                    <div class="text">
                        Выберите интервал времени пребывания в комплексе. Рекомендуем прибыть на рецепцию
                        за 15 минут.
                        <div class="text__title">
                            Условия:
                        </div>
                        <ul class="text__list">
                            <li>Первое</li>
                            <li>Второе</li>
                            <li>Третье</li>
                            <li>Четвертое</li>
                        </ul>
                    </div>
                </div>

            </TemplateCard>
            <TemplateCard class="guests" title="Количество гостей">
                <GuestComponent
                                :key="key"
                                v-for="(price,key) in pricesValue"
                                v-model:count="pricesValue[key].quantity"
                                v-model:dr="price.dr"
                                :title="price.title1"
                                :active="price.active"
                                :subtitle="price.subtitle"
                                :tool-tip-text="price.tooltip"
                                :use-dr="typeof(price.sale)!=='undefined'"/>

            </TemplateCard>
        </div>
        <form @submit.prevent="payment" class="ticketTotals">
            <div class="ticketTotals__title">
                Заполните данные и оплатите билет
            </div>
            <div class="ticketTotals__cards">
                <div class="ticketTotals__card">
                    <UserData class="cartInfo__section"
                              v-model:errorName="orderErrors.name"
                              v-model:errorEmail="orderErrors.email"
                              v-model:errorPhone="orderErrors.phone"
                              v-model:userName="userName"
                              v-model:phone="phone"
                              v-model:email="email"

                    />
                </div>
                <div class="ticketTotals__card">
                    <PaymentData class="cartInfo__section"
                                 v-model:code="paymentCode"
                    />
                </div>
                <div class="ticketTotals__card">
                    <div class="ticketTotals__card__title">Сводка заказа</div>
                    <div class="ticketTotals__row"><span>Дата</span><span>{{ formatDate }}</span></div>
                    <div class="ticketTotals__row"><span>Временной интервал</span><span>{{ periodValue }}</span></div>
                    <div class="ticketTotals__row"><span>Продолжительность</span><span>{{ tatiffFormat }}</span></div>
                    <div class="ticketTotals__row" v-for="item in totalClientsFilter">
                        <span>{{ item.title2 }}</span><span>{{ item.quantity }}</span></div>

                    <div class="ticketTotals__total">
        <span>
        Итого</span>
                        <span>  {{ total }}</span>
                    </div>
                    <div v-if="orderErrors.other" class="otherError" v-html="orderErrors.other">

                    </div>
                    <button :disabled="submitBlocked" type="submit" class="ticketTotals__btnConfirm"
                            v-html="textButton">
                    </button>
                    <div class="ticketTotals__agree">
                        <AgreeController :content="policy.content" v-model="policy.value"/>
                        <AgreeController :content="rules.content" v-model="rules.value"/>
                        <AgreeController :content="ads.content" v-model="ads.value"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <RulesComponent/>
    <QrModal @close="close" :order="qrModal.order" :show="qrModal.show" :link="qrModal.link" :img="qrModal.img"/>
</template>

<script setup>
import TicketCalendar from "./controls/calendar/TicketCalendar.vue";
import TemplateCard from "./TemplateCard.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import {currentDay} from "../utils/currentDay";
import SelectButton from "./controls/selectButton.vue";
import GuestComponent from "./controls/GuestComponent.vue";
import _ from "lodash";
import QrModal from "./QrModal.vue";
import UserData from "./UserData.vue";
import PaymentData from "./PaymentData.vue";
import {priceFormat} from "../module/wnumb.js"
import AgreeController from "./controls/AgreeController.vue";
import {DateTime} from "luxon/build/es6/luxon";
import RulesComponent from "./RulesComponent.vue";

function close() {
    qrModal.value.show = false
}


const timeTimer = 600;
const date = ref(currentDay.startOf('day').plus({days: 1}).valueOf());
const formatDate = computed(() => {
    return DateTime.fromMillis(date.value).toLocaleString({
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        }
    );
})
const pricesValue = ref(null)
const timer = ref(DateTime.fromSeconds(timeTimer));
const timerFormat = computed(() => {
    return timer.value.toFormat('mm:ss')
})
const timerStart = () => {
    setInterval(() => {
        if (timer.value.valueOf() > 0) {
            timer.value = timer.value.minus({seconds: 1})
        } else {
            timer.value = timer.value.set({seconds: timeTimer});
        }


    }, 1000)
}

const tariffValue = ref(null)
const periodValue = ref(null)

const submitBlocked = computed(() => {
    return !(rules.value.value && policy.value.value && !sending.value && totalValue.value)
})
const sending = ref(false);
const textButton = ref('Оплатить онлайн');
const orderErrors = ref(
    {
        name: {},
        phone: {},
        email: {},
        other: ''
    }
)
const userName = ref('');
const phone = ref('');
const email = ref('');
const paymentCode = ref('card')
const qrModal = ref({
    urlGetStatus: '',
    show: false,
    order: 0,
    img: '',
    link: '',
})
const time = ref(null)

const counter = ref(
    {
        grownMan: {counter: 0, dr: false},
        child: {counter: 0, dr: false},
        beneficiary: {counter: 0, dr: false},
        baby: {counter: 0, dr: false}
    })
const dataDate = computed(() => {
    return window.settingTicket.days[date.value]
})
const setting = computed(() => {
    return window.settingTicket.setting
})
const tatiffFormat = computed(() => {
    return tariffs.value.find((item) => item.value === tariffValue.value).title
})
const ticketPosition = computed(() => {

    const position = [];

    totalClients.value.map((item) => {
        if (item.dr) {
            position.push({
                'title': item.title,
                'value': item.value,
                'value_title': item.title3,
                'count': 1,
                'price': item.sale,
                'dr': true,
            })
            if (item.quantity - 1) {
                for (let i = 0; i < item.quantity - 1; i++) {
                    position.push({
                        'title': item.title,
                        'value': item.value,
                        'value_title': item.title3,
                        'count': 1,
                        'price': item.main,
                        'dr': false,
                    })
                }
            }
        } else {
            for (let i = 0; i < item.quantity; i++) {
                position.push({
                    'title': item.title,
                    'value': item.value,
                    'value_title': item.title3,
                    'count': 1,
                    'price': item.main,
                    'dr': false,
                })
            }
        }
    })
    return position
})
const totalValue = computed(() => {
  return ticketPosition.value.reduce((currentSum, currentItem) => {
        return currentSum + currentItem.price
    }, 0);
})
const total = computed(() => {
    return priceFormat.to(totalValue.value)
})


const policy = ref({
    value: true,
    content: `Согласен с <a target="_blank" href="/media/Main/MainSettings/2021-01-20/687c2733f148a16f4ced785b7f0633b7.pdf"> условием обработки  <br> персональных данных</a>`,
});
const rules = ref({
    value: true,
    content: `Ознакомлен с  <a target="_blank" href="/">правилами покупки электронных сертификатов,</a> а также <a target="_blank" href="/">правилами посещения термального центра</a>`,
});
const ads = ref({
    value: false,
    content: `Согласен на получение рекламной <br> информации по каналам связи`,
});
const periods = computed(() => {
    const periods = [];
    for (const keyPeriod in dataDate.value.period) {
        const period = dataDate.value.period[keyPeriod];
        let active = false;
        for (const tariffKey in period.tariff) {
            const tariff = period.tariff[tariffKey];
            active = activePrice(tariff.prices)
        }
        periods.push({
            'value': keyPeriod,
            'title': keyPeriod,
            'active': active
        })
    }
    return periods
})

const tariffs = computed(() => {
    const tariffs = [];
    for (const keyTariff in dataDate.value.period[periodValue.value].tariff) {
        const tariff = dataDate.value.period[periodValue.value].tariff[keyTariff]
        let active = false;
        active = activePrice(tariff.prices)
        tariffs.push({
            'value': keyTariff,
            'title': tariff.title,
            'active': active
        })
    }
    return tariffs
})





const prices = computed(()=>{
    const prices = [];
    for (const priceKey in dataDate.value.period[periodValue.value].tariff[tariffValue.value].prices) {
        const price = dataDate.value.period[periodValue.value].tariff[tariffValue.value].prices[priceKey]
        const priceObject = {
            value: priceKey,
            ...getName(priceKey).titles,
            quantity: 0,
            dr: false
        }
        priceObject.main = price.main
        if (price.sale) {
            priceObject.active = ((price.main > 0 || price.sale > 0) && price.main !== '-')
            priceObject.sale = price.sale
        } else {
            priceObject.active = (price.main > 0 && price.main !== '-')
        }
        if (priceKey==='baby'){
            priceObject.active=true
        }
        prices.push(priceObject)
    }
    return prices
})

const getName = (key) => {
    return setting.value[key]
}
const totalClientsFilter=computed(()=>{
    return  totalClients.value.filter((item)=>{
        return item.quantity
    });
})
const totalClients = ref([]);
//
// const totalClients = computed(() => {
//     const clients = [];
//     console.log( pricesValue.value)
//
//     //
//     // if (counter.value.grownMan.counter) {
//     //     clients.push({
//     //         'title': 'Гостей (взрослых)',
//     //         'value': 'grownMan',
//     //         'value_title': 'Взрослый билет',
//     //         'count': counter.value.grownMan.counter,
//     //         'price': prices.value.grownMan.main,
//     //         'sale': prices.value.grownMan.sale,
//     //         'dr': counter.value.grownMan.dr,
//     //     })
//     // }
//     // if (counter.value.child.counter) {
//     //     clients.push({
//     //         'title': 'Гостей (детей)',
//     //         'value': 'child',
//     //         'value_title': 'Детский билет',
//     //         'count': counter.value.child.counter,
//     //         'price': prices.value.child.main,
//     //         'sale': prices.value.child.sale,
//     //         'dr': counter.value.child.dr,
//     //     })
//     // }
//     // if (counter.value.beneficiary.counter) {
//     //     clients.push({
//     //         'title': 'Гостей (льготников)',
//     //         'value': 'beneficiary',
//     //         'value_title': 'Льготный билет',
//     //         'count': counter.value.beneficiary.counter,
//     //         'price': prices.value.beneficiary.main,
//     //         'sale': prices.value.beneficiary.sale,
//     //         'dr': counter.value.beneficiary.dr,
//     //     })
//     // }
//     // if (counter.value.baby.counter) {
//     //     clients.push({
//     //         'title': 'Гостей (малышей)',
//     //         'value': 'baby',
//     //         'value_title': 'Билет для малышей',
//     //         'count': counter.value.baby.counter,
//     //         'price': prices.value.baby.main,
//     //         'sale': prices.value.baby.sale,
//     //         'dr': counter.value.baby.dr,
//     //     })
//     // }
//     return clients;
//
//
// })

async function getStatus($iter = 0) {
    setTimeout(async () => {
        if ($iter < 100) {
            let data = await fetch(qrModal.value.urlGetStatus);
            data = await data.json();
            if (data.status === 200) {
                location = data.redirect
            } else {
                if (qrModal.value.show) {
                    await getStatus($iter++)
                }
            }
        }
    }, 3000)
}

const firstTariff = () => {
    const index = tariffs.value.findIndex((item) => item.active)
    if (index >= 0) {
        return tariffs.value[index].value
    } else {
        return null
    }
}
const firstPeriod = () => {
    const index = periods.value.findIndex((item) => item.active)
    if (index >= 0) {
        return periods.value[index].value
    } else {
        return null
    }
}
const activePrice = (prices) => {
    let active = false;
    for (const priceKey in prices) {
        let price = prices[priceKey]
        if (priceKey !== 'baby') {
            if (price.sale) {
                if ((price.main !== '-') && (price.main > 0 || price.sale > 0)) {
                    active = true;
                }
            } else {
                if (price.main !== '-' && price.main > 0) {
                    active = true;
                }
            }
        }
    }
    return active
}

watch(date, () => {
    periodValue.value = firstPeriod()
    pricesValue.value = _.cloneDeep(prices.value)
})

watch(periodValue, () => {
    tariffValue.value = firstTariff()
    pricesValue.value = _.cloneDeep(prices.value)
})

watch(tariffValue, () => {
    pricesValue.value = _.cloneDeep(prices.value)
})
watch(()=>{
    return _.cloneDeep(pricesValue.value)
},(newValue,oldValue)=>{
    totalClients.value=[]
    newValue.map(item=>  totalClients.value.push(item))
})



onBeforeMount(() => {
    periodValue.value = firstPeriod()
    tariffValue.value = firstTariff()
    pricesValue.value = _.cloneDeep(prices.value)
})

timerStart();

const payment = () => {
    orderErrors.value = {
        name: {},
        phone: {},
        email: {},
        other: ''
    }
    sending.value = true;
    textButton.value = 'Оплатить онлайн <span class="sending"><span>.</span><span>.</span><span>.</span></span>'
    let data = new FormData();

    ticketPosition.value.map((item, index) => {
        data.append(`ticket[${index}][price]`, +item.price)
        data.append(`ticket[${index}][quantity]`, +item.count)
        data.append(`ticket[${index}][dr]`, +item.dr)
        data.append(`ticket[${index}][value]`, item.value)
        data.append(`ticket[${index}][name]`, userName.value)
        data.append(`ticket[${index}][phone]`, phone.value)
        data.append(`ticket[${index}][email]`, email.value)
        data.append(`ticket[${index}][value_title]`, item.value_title)
        data.append(`ticket[${index}][value]`, item.value)
        data.append(`ticket[${index}][time]`, date.value)
        data.append(`ticket[${index}][tariff]`, tariffValue.value)
        data.append(`ticket[${index}][period]`, periodValue.value)

    })
    data.append(`ticketOrder[email]`, email.value)
    data.append(`ticketOrder[phone]`, phone.value)
    data.append(`ticketOrder[name]`, userName.value)
    data.append(`ticketOrder[payment_method]`, paymentCode.value)
    data.append(`ticketOrder[time]`, date.value)
    data.append(`ticketOrder[tariff]`, tariffValue.value)
    data.append(`ticketOrder[period]`, periodValue.value)
    let urls = {
        card: "/payments/payment/ticket/acquiring",
        qr: "/payments/payment/ticket/qr",
    }


    fetch(urls[paymentCode.value], {
        method: 'POST',
        body: data,
        headers: {
            'credentials': 'same-origin',
            'X-Requested-With': 'XMLHttpRequest',
        },
    }).then(async data => {
        data.json().then(data => {
            sending.value = false;
            textButton.value = 'Оплатить онлай';
            if (data.state === 'fail') {
                let other = [];
                data.error.map((item) => {
                    if (item.key === 'other') {
                        other.push(item.message)
                    } else {
                        orderErrors.value[item.key] = {value: true, text: item.message}
                    }
                    if (other.length) {
                        orderErrors.value.other = other.join('</br>')
                    }


                })
            } else {
                if (data.state === 'success') {

                    if (paymentCode.value === 'card') {
                        window.location = data.redirect
                    }
                    if (paymentCode.value === 'qr') {
                        qrModal.value.show = true;
                        qrModal.value.img = data.redirect.img;
                        qrModal.value.link = data.redirect.link;
                        qrModal.value.order = data.redirect.order;
                        qrModal.value.urlGetStatus = data.redirect.urlGetStatus;
                        getStatus()


                    }
                }
            }
        })
    })
}

</script>


<style scoped lang="scss">
.otherError {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #E1002E;
  margin-top: 24px;
}

.timer {
  margin-top: 5.208vw;
  font-size: 26px;
  line-height: 34px;
  font-weight: 500;

  span {
    color: #E73C5F;
  }
}

.ticketController {
  display: grid;
  grid-template-columns:repeat(6, 1fr);
  grid-gap: 1.25vw;
  transition: 0.3s linear all;
  margin-top: 48px;
  grid-template-areas:
    'f f s s t t';

  &:deep(.card) {
    &:nth-child(1) {
      grid-area: f;
    }

    &:nth-child(2) {
      grid-area: s;
    }

    &:nth-child(3) {
      grid-area: t;
    }
  }

  &__info {
    margin-top: 24px;

    li {
      padding-left: 26px;
      position: relative;
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      color: #7F7F94;

      &:last-child {
        margin-bottom: 0px;
      }

      &:before {
        left: 0;
        top: 0;
        position: absolute;
        content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2.5C8.64583 2.5 7.38281 2.84505 6.21094 3.53516C5.07812 4.1862 4.1862 5.07812 3.53516 6.21094C2.84505 7.38281 2.5 8.64583 2.5 10C2.5 11.3542 2.84505 12.6172 3.53516 13.7891C4.1862 14.9219 5.07812 15.8203 6.21094 16.4844C7.38281 17.1615 8.64583 17.5 10 17.5C11.3542 17.5 12.6172 17.1615 13.7891 16.4844C14.9219 15.8203 15.8203 14.9219 16.4844 13.7891C17.1615 12.6172 17.5 11.3542 17.5 10C17.5 8.64583 17.1615 7.38281 16.4844 6.21094C15.8203 5.07812 14.9219 4.1862 13.7891 3.53516C12.6172 2.84505 11.3542 2.5 10 2.5ZM10 3.75C11.1328 3.75 12.1875 4.03646 13.1641 4.60938C14.1016 5.15625 14.8438 5.89844 15.3906 6.83594C15.9635 7.8125 16.25 8.86719 16.25 10C16.25 11.1328 15.9635 12.1875 15.3906 13.1641C14.8438 14.1016 14.1016 14.8438 13.1641 15.3906C12.1875 15.9635 11.1328 16.25 10 16.25C8.86719 16.25 7.8125 15.9635 6.83594 15.3906C5.89844 14.8438 5.15625 14.1016 4.60938 13.1641C4.03646 12.1875 3.75 11.1328 3.75 10C3.75 8.86719 4.03646 7.8125 4.60938 6.83594C5.15625 5.89844 5.89844 5.15625 6.83594 4.60938C7.8125 4.03646 8.86719 3.75 10 3.75ZM9.375 6.25V11.25H10.625V6.25H9.375ZM9.375 12.5V13.75H10.625V12.5H9.375Z' fill='%23E73C5F'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

.ticketTotals {
  margin-top: 80px;
  border-radius: 12px;
  background: #ffffff;
  --paddingLR: 2.5vw;
  color: #000000;
  margin-bottom: 150px;
  padding: 48px var(--paddingLR);

  &__agree {
    margin-top: 32px;
  }

  &__row {
    color: #7F7F94;
    padding: 8px 0;
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #DFE2E3;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;

      &:last-child {
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
      }
    }
  }

  &__btnConfirm {
    border-radius: 12px;
    transition: 0.3s ease all;
    margin-top: 35px;
    padding: 20px;
    background: #E73C5F;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    &:hover {
      background: #f8466a;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__cards {
    display: flex;
  }

  &__title {
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    font-size: 26px;
    margin-bottom: 48px;
  }

  &__card {
    &__title {
      font-family: 'TT Norms';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
    }

    &:nth-child(1) {
      width: 100%;
      max-width: 420px;
      margin-right: var(--paddingLR);
    }

    &:nth-child(2) {
      width: 100%;
      max-width: 444px;
      margin-right: var(--paddingLR);
    }

    &:nth-child(3) {
      width: 100%;
      border-left: 1px solid #DFE2E3;
      padding-left: calc(var(--paddingLR) / 2);
    }
  }
}

.guests {
  &:deep(.card__body) {
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    grid-template-areas:
        'f f'
        's s'
        't t'
        'fr fr';

    .guestRow {
      &:nth-child(1) {
        grid-area: f;
      }

      &:nth-child(2) {
        grid-area: s;
      }

      &:nth-child(3) {
        grid-area: t;
      }

      &:nth-child(4) {
        grid-area: fr;
      }
    }

  }


}


.wrapButtons {
  --gap: 3.6%;

  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;

  &--fullWidth {
    display: grid;
    grid-template-columns: repeat(4, calc((100% - (var(--gap) * 3)) / 4));
    grid-column-gap: var(--gap);
  }
}

hr {
  border: none;
  border-bottom: 1px solid #393F67;
  margin-top: 16px;
  margin-bottom: 16px;
}

.text {
  margin-top: 16px;
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-width: 376px;

  &__title {
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 6px;
  }

  &__list {
    list-style: disc;
    padding-left: 16px;
  }
}

@media screen and (max-width: 1700px) {

  .wrapButtons {

    &--fullWidth {
      display: grid;
      grid-template-columns: repeat(2, calc((100% - (var(--gap))) / 2));
      grid-column-gap: var(--gap);
    }

    &:deep(.selectButton) {
      width: 100%;
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 1246px) {
  .ticketTotals {
    &__cards {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__card {

      &:nth-child(1) {
        max-width: calc(50% - (var(--paddingLR) / 2));
        margin-right: 0px
      }

      &:nth-child(2) {
        max-width: calc(50% - (var(--paddingLR) / 2));
        margin-right: 0px
      }

      &:nth-child(3) {
        border-left: none;
        padding-left: 0;
        width: 100%;
        border-top: 1px solid #DFE2E3;
        padding-top: 24px;
      }


    }
  }
  .ticketController {
    grid-template-areas:
            'f f f s s s'
            't t t t t t';
  }
  .guests {
    &:deep(.card__body) {
      display: grid;
      grid-template-columns:repeat(2, 1fr);
      grid-template-areas:
        'f s'
        't fr';
      grid-gap: 1.25vw;
    }
  }


}

@media screen and (max-width: 768px) {
  .ticketController {
    margin-top: 24px;
    grid-template-areas:
            'f f f f f f'
            's s s s s s'
            't t t t t t';
    grid-row-gap: 32px;

    &__info {
      margin-top: 20px;

      li {
        margin-bottom: 2px;
      }
    }
  }
  .text {
    &__title {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .guests {
    &:deep(.card__body) {
      grid-template-areas:
        'f f'
        's s'
        't t'
        'fr fr';
    }
  }

  .ticketTotals {
    margin-top: 32px;
    padding: 20px;
    padding-bottom: 44px;
    margin-bottom: 80px;

    &__card {
      margin-bottom: -24px;
    }

    &__title {
      margin-bottom: 0px;
      font-size: 22px;
      line-height: 30px;
    }

    &__btnConfirm {
      margin-top: 24px;
    }

    &__total {
      margin-top: 25px;

      span {
        &:last-child {
          font-size: 22px;
          line-height: 30px;
        }
      }
    }

    &__card {
      &__title {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 4px;
      }

      &:nth-child(1) {
        max-width: 100%;
        width: 100%;
      }

      &:nth-child(2) {
        max-width: 100%;
        width: 100%;
      }

      &:nth-child(3) {
        width: 100%;
      }


    }
  }
  .timer {
    font-size: 18px;
    line-height: 26px;
    margin-top: 24px;
    text-align: center;

    span {
      display: block;
      color: #E73C5F;
      text-align: center;
    }
  }

}
</style>