<template>
    <div class="rules">
        <div class="container">
            <div class="rules__title">
                Основные правила посещения
            </div>
            <div class="rules__body">
                <ol>
                    <li> Вход в раздевалки только в сменной обуви.</li>
                    <li>Верхнюю одежду необходимо сдавать в гардероб.</li>
                    <li>Для оплаты услуг комплекса по льготным ценам, необходимо каждый раз предъявлять
                        удостоверение.
                    </li>
                    <li>При приобретении почасового посещения, или абонемента в термальную зону на 1 месяц, за не
                        использованные минуты деньги не возвращаются.
                    </li>
                    <li>Дети старше 5 лет не могут находится в раздевалке другого пола, для совместного переодевания
                        с родителем нужно использовать семейную раздевалку.
                    </li>
                    <li>При оформлении браслета у менеджеров-консультантов необходимо предъявить заполненную
                        расписку об ознакомлении с правилами посещения комплекса.
                    </li>
                    <li>Если гость комплекса выходит через турникет у рецепции, после окончания работы комплекса (по
                        режиму работу комплекса), то тариф, по которому рассчитывается время пребывания в комплексе,
                        увеличивается в 3 раза.
                    </li>
                    <li>Запрещается приносить свою еду и напитки.</li>
                    <li>Перед посещением бассейна обязательно принять душ, в том числе после парных.</li>
                    <li>Запрещается лить воду на каменки в парных.</li>
                    <li>Гость комплекса обязан посещать бассейны только в шапочках для плавания или с высоко
                        забранными волосами в пучок и покрытых платком. При отказе соблюдать правила, комплекс
                        оставляет за собой право запретить посещение бассейнов.
                    </li>
                    <li>Запрещается ходить по бортикам бассейнов и прыгать с них</li>
                    <li>По разовому посещению и по абонементу термальной зоны время начинает идти с турникетов у
                        ресепшн и заканчивается на выходе здесь же.
                    </li>
                    <li>ОБЯЗАТЕЛЬНО с собой иметь хорошее настроение и желание отдохнуть.</li>
                </ol>
                <p>
                    Для посещения термальной зоны необходимо с собой иметь: купальный костюм, принадлежности для
                    принятия душа, сланцы, полотенца, шапочку ( очки, халат и т.п. по желанию)
                </p>
            </div>

        </div>
    </div>

</template>

<script setup>

</script>

<style scoped lang="scss">
.rules {
  background: #272F64;
  padding: 150px 0;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    line-height: 74px;
    text-transform: uppercase;
  }

  &__body {
    margin-top: 36px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;

    ol {
      list-style: decimal;

      li {
        font-weight: 500;
        font-size: 18px !important;
        line-height: 26px;

      }
    }

    p {
      margin-top: 26px
    }

  }
}

@media screen and (max-width: 767px) {
  .rules {
    padding: 48px 20px;

    .container {
      padding: 0;
    }

    &__title {
      line-height: 30px;
      font-size: 24px;

    }

    &__body {

      margin-top: 17px;
      font-size: 16px;
        line-height: 22px;

      ol {
        padding-left: 28px;

          li{
            font-size: 16px;
            line-height: 22px;
        }

      }
    }

  }
}
</style>