<template>
    <div class="counter br12">
        <button :disabled="!active" @click="decrement()" class="counter__btn counter__btn--minus">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <rect x="1.25" y="8.75" width="17.5" height="2.5" rx="1.25" fill="white"/>
            </svg>
        </button>
        <input class="counter__input" type="text" disabled v-model="quantity">
        <button :disabled="!active" @click="increment()" class="counter__btn counter__btn--plus">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                        d="M17.6562 8.90625H11.0938V2.34375C11.0938 1.6875 10.6563 1.25 10 1.25C9.34375 1.25 8.90625 1.6875 8.90625 2.34375V8.90625H2.34375C1.6875 8.90625 1.25 9.34375 1.25 10C1.25 10.6563 1.6875 11.0938 2.34375 11.0938H8.90625V17.6562C8.90625 18.3125 9.34375 18.75 10 18.75C10.6563 18.75 11.0938 18.3125 11.0938 17.6562V11.0938H17.6562C18.3125 11.0938 18.75 10.6563 18.75 10C18.75 9.34375 18.3125 8.90625 17.6562 8.90625Z"
                        fill="white"/>
            </svg>
        </button>
    </div>
</template>

<script setup>
import {useModelWrapper} from "../../utils/modelWrapper";

const emits = defineEmits()
const props = defineProps({
        modelValue: {
            default: 0,
            type: Number
        },
        active: {
            type: Boolean,
            default: true
        },
    }
)
const quantity = useModelWrapper(props, emits)
const decrement = () => {

    if (quantity.value > 0) {
        quantity.value--
    }
}
const increment = () => {
    quantity.value++;
}
</script>

<style scoped lang="scss">
.counter {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #393F67;
  height: 56px;
  border-radius: 12px;

  &__input {
    width: 40px;
    text-align: center;
    color: #fff;
    font-weight: 500;
  }

  &__btn {
    transition: 0.3s ease all;
    background: transparent;
    display: flex;
    height: 30px;
    width: 30px;
    align-content: center;
    justify-content: center;
    align-items: center;

    svg {
      path, rect {
        transition: 0.3s ease all;
      }
    }

    &:hover {
      svg {
        path, rect {
          fill: #E73C5F;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .counter {
    width: 100px;
    height: 48px;

    &__input {
      width: 16px;;
    }

    &__btn {
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

}
</style>