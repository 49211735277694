function initParallax() {
  const miniParallaxContainers = document.querySelectorAll('[data-card-parallax-container]');
  miniParallaxContainers.forEach((container) => {
    const miniParallax = new parallaxHandler(container, {
      back: '[data-card-parallax-back]',
      perspective: 5,
    });
  });

  const topParallaxContainer = document.querySelector('[data-parallax-container]');
  if (topParallaxContainer) {
    const topContent = new parallaxHandler(topParallaxContainer, {
      back: '[data-parallax-back]',
      perspective: 3,
      shadow: '[data-parallax-shadow]',
      opacityIntensiv: 0.9,
    });
  }
}

function setTransition() {
  const element = document.querySelector('[data-need-transition]:not(.transition)');
  if (element) {
    setTimeout(() => {
      element.classList.add('transition');
    }, 300);
  }
}

class parallaxHandler {
  constructor(container, options) {
    this.options = {
      back: '',
      base: '',
      shadow: '',
      perspective: 3,
      opacityIntensiv: 0.8,
    };
    this.options = Object.assign({}, this.options, options);

    this.container = container;
    if (this.container) {
      this.back = this.container.querySelector(this.options.back);
      if (this.options.base !== '') {
        this.base = this.container.querySelector(this.options.base);
      }
      if (this.options.shadow !== '') {
        this.shadow = this.container.querySelector(this.options.shadow);
      }
      setTimeout(() => {
        this.init();
      }, this.getPrepareTimeout());
    }
  }

  init() {
    this.lastScrollPosition = null;
    window.requestAnimationFrame(() => this.calculate());
  }

  calculate() {
    const scrollPosition = window.pageYOffset;

    if (this.lastScrollPosition === null || this.lastScrollPosition !== scrollPosition) {
      this.lastScrollPosition = scrollPosition;

      const topBorderPosition = this.container.getBoundingClientRect().top;
      const bottomBorderPosition = Math.round(this.container.offsetHeight + topBorderPosition);

      if (this.back && topBorderPosition <= window.innerHeight && bottomBorderPosition > 0) {
        this.translateBack();
      }

      if (this.shadow && topBorderPosition <= 0 && bottomBorderPosition > 0) {
        this.blackoutBack(topBorderPosition);
      }
    }
    window.requestAnimationFrame(() => this.calculate());
  }

  translateBack() {
    const topBorderPosition = this.container.getBoundingClientRect().top;
    const preOffset = -1 * (window.innerHeight - topBorderPosition - this.container.offsetHeight);
    const translateOffset = Math.round(preOffset / this.options.perspective);
    this.back.style.transform = 'translateY(' + translateOffset + 'px)';
  }

  blackoutBack(topBorderPosition) {
    const ratio = -1 * (topBorderPosition / this.shadow.offsetHeight);
    const opacity = (ratio * this.options.opacityIntensiv).toFixed(2);
    this.shadow.style.opacity = opacity;
  }

  getPrepareTimeout() {
    let timeout = 0;
    if (process.env.NODE_ENV !== 'production') {
      timeout = 2000;
    }
    return timeout;
  }
}

document.addEventListener("DOMContentLoaded", initParallax);
document.addEventListener("DOMContentMutated", initParallax);
document.addEventListener("VhVarUpdate", setTransition);