<template>
    <div class="calendar">
        <div class="calendar__head">
            <button type="button" class="calendar__prevMonth calendar__matrix__item "
                    @click="month--">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="38" height="38" viewBox="-12 0 32 32"
                     version="1.1">
                    <path d="M7.28 23.28c-0.2 0-0.44-0.080-0.6-0.24l-6.44-6.44c-0.32-0.32-0.32-0.84 0-1.2l6.44-6.44c0.32-0.32 0.84-0.32 1.2 0 0.32 0.32 0.32 0.84 0 1.2l-5.8 5.84 5.84 5.84c0.32 0.32 0.32 0.84 0 1.2-0.16 0.16-0.44 0.24-0.64 0.24z"/>
                </svg>
            </button>
            <span class="calendar__title">
          {{ monthFormat }}
      </span>
            <button type="button" class="calendar__nextMonth calendar__matrix__item "
                    @click="month++">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="38" height="38" viewBox="-12 0 32 32"
                     version="1.1">
                    <path d="M0.88 23.28c-0.2 0-0.44-0.080-0.6-0.24-0.32-0.32-0.32-0.84 0-1.2l5.76-5.84-5.8-5.84c-0.32-0.32-0.32-0.84 0-1.2 0.32-0.32 0.84-0.32 1.2 0l6.44 6.44c0.16 0.16 0.24 0.36 0.24 0.6s-0.080 0.44-0.24 0.6l-6.4 6.44c-0.2 0.16-0.4 0.24-0.6 0.24z"/>
                </svg>
            </button>
        </div>
        <div class="calendar__matrix">
            <span class="calendar__matrix__item">пн</span>
            <span class="calendar__matrix__item">вт</span>
            <span class="calendar__matrix__item">ср</span>
            <span class="calendar__matrix__item">чт</span>
            <span class="calendar__matrix__item">пт</span>
            <span class="calendar__matrix__item">сб</span>
            <span class="calendar__matrix__item">вс</span>
            <DayController :class="{
              'calendar__matrix__item--btnActive':item.active,
              'calendar__matrix__item--btnDisabled':item.isCurrentMonth,
          }" class="calendar__matrix__item calendar__matrix__item--btn " :active="item.active" :active:="item.active"  :key="item"
                           v-for="item in calendarItems" :title="item.day" :value="item.timestamp"
                           v-model="selectData"></DayController>
        </div>
    </div>
</template>

<script setup>

import DayController from "./DayController.vue";
import {computed, ref} from "vue";
import {useModelWrapper} from "../../../utils/modelWrapper";
import {currentDay} from "../../../utils/currentDay";

const emit = defineEmits();
const props = defineProps({
    modelValue: {
        type:Number,
        required:true
    }
})
const selectData = useModelWrapper(props,emit)

const daysInLastMonth = () => {
    return currentDay.minus({month: 1}).daysInMonth
}
const daysInCurrentMonth = () => {
    return currentDay.daysInMonth
}

const getFirstDay = () => {
    return currentDay.set({days: 1})
}
const getLastDay = () => {
    return currentDay.set({days: daysInCurrentMonth()})
}



const currentMonth = currentDay.month
const month = ref(currentMonth);


const firstDayMonth = computed(() => {
    return currentDay.set({month: month.value, days: 1})
})


const lastDayMonth = computed(() => {
    return currentDay.set({month: month.value, days: firstDayMonth.value.daysInMonth})
})


const startRange = computed(() => {
        if (firstDayMonth.value.weekday === 1) {
            return firstDayMonth.value;
        } else {
            return firstDayMonth.value.minus({days: (firstDayMonth.value.weekday - 1)});
        }
    }
)


const andRange = computed(() => {
        if (lastDayMonth.value.weekday === 7) {
            return lastDayMonth.value;
        } else {
            return lastDayMonth.value.plus({days: (7 - lastDayMonth.value.weekday)});
        }
    }
)

const monthFormat = computed(() => {
    return firstDayMonth.value.toLocaleString({month: 'long'})
})

const firstDayFormat = computed(() => {
    return startRange.value.toFormat('dd.MM.yyyy')
})
const lastDayFormat = computed(() => {
    return andRange.value.toFormat('dd.MM.yyyy');
})
const activeDays = {
    start: currentDay.plus({days: 1}),
    end: currentDay.plus({days: 15})
}
const getActive=(date)=>{

   const in2Week= activeDays.start.startOf('day') <= date.startOf("day") && date.startOf("day") < activeDays.end.startOf("day");
   let activeDaySetting = false
   if (window.settingTicket.days[date.startOf("day").valueOf()]){
       for (const periodKey in window.settingTicket.days[date.startOf("day").valueOf()].period) {
           const period = window.settingTicket.days[date.startOf("day").valueOf()].period[periodKey]
           for (const tariffKey in period.tariff){
               const tariff =  period.tariff[tariffKey]
               let active = false;
               for (const priceKey in tariff.prices){
                   let price = tariff.prices[priceKey]
                   if (priceKey !=='baby'){
                       if (price.sale){
                           if((price.main !=='-')&&(price.main>0||price.sale>0)){
                               active=true;
                           }
                       }else{
                           if(price.main !=='-'&&price.main>0){
                               active=true;
                           }
                       }
                   }
               }
               activeDaySetting=active
           }
       }
   }else{
       return false
   }
    return in2Week && activeDaySetting;
}
const calendarItems = computed(() => {
    let items = [];
    let date = startRange.value;

    while (date.startOf("day") <= andRange.value.startOf("day")) {
        items.push({
            day: date.toFormat('d'),
            timestamp: date.startOf("day").valueOf(),
            isCurrentMonth: currentMonth === date.month,
            active:getActive(date)
        })
        date = date.plus({days: 1})
    }
    return items
})


</script>

<style scoped lang="scss">

.calendar {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    button {

    }

  }

  &__nextMonth, &__prevMonth {
    background: #393F67;
    border-radius: 8px;
    border: none;
    color: #7F7F94;
    svg {
      transition: 0.3s ease all;
    }

    &:hover {
      background: #353b60;
      color: #E73C5F;

      svg {
        fill: #E73C5F
      }
    }
  }

  &__title {
    font-family: 'TT Norms';
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
  }

  &__matrix {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-row-gap: 14px;
    align-items: center;
    justify-content: space-between;

    &__item {
      width: 42px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease all;
      font-family: 'TT Norms';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      border-radius: 8px;
      &--btn {
        color:#393F67;
      }
      &--btnDisabled{
        background: #393F67;
        color:#7F7F94 ;
      }
      &--btnActive{
        background: #262D5B;
        color: #fff;
        border: 2px solid #DFE2E3;
        &:hover,&.checked{
          color: #E73C5F;
          border:  2px solid  #E73C5F;
        }
      }


    }
  }
}
@media screen and (max-width: 768px) {
  .calendar {
    &__head {
      button {
      }
    }
    &__nextMonth, &__prevMonth {
      svg {
      }
      &:hover {
        svg {
        }
      }
    }

    &__title {
      font-size: 18px;
      line-height: 26px;
    }

    &__matrix {


      &__item {
        width: 32px;
        height: 32px;
      }}}
}

</style>