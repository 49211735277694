import { tns } from 'tiny-slider/src/tiny-slider';
import _ from 'lodash';

function initSliders() {
  const doubleSlidersList = document.querySelectorAll('[data-double-container]');
  if (doubleSlidersList) {
    doubleSlidersList.forEach((container) => {
      const doubleSlider = new DoubleSlider(container);
    });
  }

  const mainSlidersList = document.querySelectorAll('[data-main-slider-container]');
  if (mainSlidersList) {
    mainSlidersList.forEach((container) => {
      const mainSlider = new MainSlider(container);
    });
  }

  const productSlidersList = document.querySelectorAll('[data-product-slider-container]');
  if (productSlidersList) {
    productSlidersList.forEach((container) => {
      const productSlider = new ProductSlider(container);
    });
  }
}

class DoubleSlider {
  constructor(container, options) {
    this.sliderElement = container.querySelector('[data-double-slider]');
    this.arrowElement = container.querySelector('[data-double-arrow]');
    if (this.sliderElement && this.arrowElement) {
      this.init(options);
    }
  }

  init(options) {
    this.options = {
      container: this.sliderElement,
      controls: false,
      nav: false,
      items: 1,
      speed: 750,
      slideBy: 1,
      loop: false,
      autoplay: false,
      autoplayButtonOutput: false,
      responsive: {
        768: {
          items: 2,
          slideBy: 2,
        },
        1246: {
          speed: 1400,
        },
      },
    };
    if (options) {
      this.options = Object.assign(options, this.options);
    }
    this.slider = tns(this.options);
    this.bindArrow();
  }

  bindArrow() {
    this.slider.events.on('transitionEnd', () => {
      const info = this.slider.getInfo();
      const slidesIndex = info.displayIndex;
      const slidesCount = info.slideCount;
      if (slidesIndex + 1 === slidesCount) {
        this.arrowElement.classList.add('_invert');
      } else if (slidesIndex === 1) {
        this.arrowElement.classList.remove('_invert');
      }
    });

    this.arrowElement.addEventListener('click', (e) => {
      e.preventDefault();
      if (this.arrowElement.classList.contains('_invert')) {
        this.slider.goTo('prev');
      } else {
        this.slider.goTo('next');
      }
    });
  }
}

class ProductSlider {
  constructor(container, options) {
    this.sliderElement = container.querySelector('[data-product-slider]');
    this.arrowLeft = container.querySelector('[data-slider-prev]');
    this.arrowRight = container.querySelector('[data-slider-next]');
    if (this.sliderElement) {
      this.init(options);
    }
  }

  init(options) {
    this.options = {
      container: this.sliderElement,
      controls: false,
      nav: false,
      items: 1,
      speed: 750,
      slideBy: 1,
      loop: false,
      autoplay: false,
      autoplayButtonOutput: false,
      responsive: {
        1246: {
          speed: 1400,
        },
      },
    };
    if (options) {
      this.options = Object.assign(options, this.options);
    }
    this.slider = tns(this.options);
    this.bindArrows();
  }

  bindArrows() {
    this.arrowLeft.addEventListener('click', () => {
      this.slider.goTo('prev');
    });

    this.arrowRight.addEventListener('click', () => {
      this.slider.goTo('next');
    });
  }
}

class MainSlider {
  constructor(container, options) {
    this.sliderContainer = container;
    this.sliderElement = this.sliderContainer.querySelector('[data-main-slider]');
    this.arrowLeft = this.sliderContainer.querySelector('[data-slider-prev]');
    this.arrowRight = this.sliderContainer.querySelector('[data-slider-next]');
    if (this.sliderElement) {
      this.init(options);
    }
  }

  onInit() {
    this.sliderContainer.classList.add('_init');
  }

  init(options) {
    this.options = {
      container: this.sliderElement,
      controls: false,
      nav: false,
      autoplay: false,
      autoplayButtonOutput: false,
      autoWidth: true,
      speed: 1900,
      slideBy: 1,
      onInit: this.onInit(),
      responsive: {
        0: {
          gutter: 25,
        },
        768: {
          gutter: 54,
        },
        1246: {
          gutter: 120,
        },
      },
    };
    if (options) {
      this.options = Object.assign(options, this.options);
    }
    this.slider = tns(this.options);
    this.bindArrow();
    this.listen();
  }

  bindArrow() {
    this.arrowLeft.addEventListener('click', () => {
      this.slider.goTo('prev');
    });

    this.arrowRight.addEventListener('click', () => {
      this.slider.goTo('next');
    });
  }

  listen() {
    let info = this.slider.getInfo();
    info.slideItems[info.index].classList.add('_active');
    for (let i = 0; i < info.index; i += 1) {
      info.slideItems[i].classList.add('_hide');
    }

    let hide = true;
    this.slider.events.on('transitionStart', () => {
      info = this.slider.getInfo();

      if (info) {
        // units - картники, ссылки, блоки с описанием
        const units = this.sliderContainer.querySelectorAll('[data-slider-unit]');
        const indexView = info.displayIndex;

        units.forEach((unit) => {
          if (unit.dataset.sliderUnit === indexView.toString()) {
            unit.classList.add('_active');
            setTimeout(() => {
              unit.classList.add('_anime');
            }, 1);
          } else {
            unit.classList.remove('_active');
            unit.classList.remove('_anime');
          }
        });
      }

      if (hide) {
        for (let i = 0; i <= info.index; i += 1) {
          info.slideItems[i].classList.remove('_hide');
        }
        hide = false;
      }
    });

    this.slider.events.on('indexChanged', () => {
      info = this.slider.getInfo();

      if (info) {
        const indexPrev = info.indexCached;
        const indexCurrent = info.index;
        info.slideItems[indexPrev].classList.remove('_active');
        info.slideItems[indexCurrent].classList.add('_active');
      }
    });

    this.slider.events.on('transitionEnd', () => {
      info = this.slider.getInfo();

      if (info) {
        const indexCurrent = info.index;
        const count = info.slideCountNew;

        if (indexCurrent + 1 === count) {
          setTimeout(() => {
            info.slideItems[indexCurrent].classList.remove('_active');
          }, 1000);
        }
      }
    });

    window.addEventListener('resize', _.debounce(() => {
      this.slider.refresh();
    }, 500));
  }
}

document.addEventListener('DOMContentLoaded', initSliders);
document.addEventListener('DOMContentMutated', initSliders);