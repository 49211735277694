import { gsap } from 'gsap';
import { ScrollScene } from 'scrollscene';
import { ScrollMagic } from 'scrollscene';

const handle = () => {
  const controller = new ScrollMagic.Controller();
  const triggerBlock = document.querySelector('[data-triggerBlock]');
  const animateTitles = document.querySelectorAll('[data-titleMagic]');
  const animateTexts = document.querySelectorAll('[data-textMagic]');
  const container = document.querySelector('[data-impact-target="mainWrapper"]');

  if (triggerBlock) {
    const t1 = gsap.timeline();
    for (const animateTitle of animateTitles) {
      t1.to(animateTitle, 0.4,
        {
          color: animateTitle.dataset.titlemagic,
        },'-=0.4');
    }
    for (const animateText of animateTexts) {
      t1.to(animateText, 0.4,
        {
          color: animateText.dataset.textmagic,
        },'-=0.4');
    }
    t1.to(container, 0.4,
      {
        backgroundColor: '#ffffff',
      }, '-=0.4');

    const sceneIn = new ScrollScene({
      triggerElement: triggerBlock,
      triggerHook: 'onLeave',
      offset: triggerBlock.dataset.triggerblock,
      gsap: {
        timeline: t1,
      },
    });
    sceneIn.Scene
      .addTo(controller);
    // .addIndicators();
  }
};

function getPrepareTimeout() {
  let timeout = 0;
  if (process.env.NODE_ENV !== 'production') {
    timeout = 1500;
  }
  return timeout;
}

function initAll() {
  setTimeout(() => {
    handle();
  }, getPrepareTimeout());
}

document.addEventListener('DOMContentLoaded', initAll);