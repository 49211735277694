<template>
  <TemplateBlock title="Способ оплаты">
      <PaymentControl :key="index"  :logo="method.logo" :title="method.title"  :code="method.code"   v-for="(method,index) in paymentMethods" class="br12" v-model="code">
      </PaymentControl>
  </TemplateBlock>
</template>
<script setup>







import PaymentControl from './controls/PaymentControl.vue';
import TemplateBlock from "./TemplateBlock.vue";
import svgs from "../utils/svgs.js";
import {useModelWrapper} from "../utils/modelWrapper";
    const svg =svgs
const emit = defineEmits();
const props =defineProps({
  code:{
    type:String,
  },
})
const paymentMethods=[
  {
    code:'card',
    title:'Онлайн',
    logo:svg.card,
  },
  {
    code:'qr',
    title:'Система Быстрых Платежей',
    logo:svg.spb,
  }
]
const code = useModelWrapper(props,emit,'code')


</script>

<style scoped>

</style>