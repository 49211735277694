function initHamburger() {
  const hamburger = document.querySelector('[data-hamburger]');
  const mainMenu = document.querySelector('[data-main-menu]');
  const body = document.querySelector('body');
  const footer = document.querySelector('footer');
  if (hamburger && mainMenu) {
    hamburger.addEventListener('click', (e) => {
      e.preventDefault();
      mainMenu.classList.toggle('_open');
      hamburger.classList.toggle('_open');
      body.classList.toggle('_fixed');
      footer.classList.toggle('_hidden');
    });
  }
}

function initSticky() {
  const header = document.querySelector('[data-header]');
  if (header) {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        header.classList.add('_sticky');
      } else {
        header.classList.remove('_sticky');
      }
    });
  }
}

function initHeader() {
  initHamburger();
  initSticky();
  const event = new Event('scroll');
  window.dispatchEvent(event);
}

window.addEventListener('DOMContentLoaded', initHeader);