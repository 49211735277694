import gsap from 'gsap';

const teammateBlockResize = () => {
  const teammateList = document.querySelectorAll('[data-teammates]');
  for (const teammate of teammateList) {
    if (teammate.classList.contains('about-team__slide_active')) {
      gsap.to('[data-sliderBlockTeammates]', { duration: 1.5, ease: "power4.out", height: teammate.offsetHeight});
    }
  }
};

const sliderSwitchDelete = () => {
  const teammateSwitches = document.querySelectorAll('[data-section]');
  for (const link of teammateSwitches) {
    if (link.querySelector('.about-team__label').classList.contains('about-team__label_active')) {
      link.querySelector('.about-team__label').classList.remove('about-team__label_active');
    }
  }
};

const postponement = () => {
  let timeout = 0;
  if (process.env.NODE_ENV !== 'production') {
    timeout = 3500;
  }
  setTimeout(() => {
    const teammateSwitches = document.querySelectorAll('[data-section]');
    const teammateList = document.querySelectorAll('[data-teammates]');
    for (const teammateSwitch of teammateSwitches) {

      teammateSwitch.addEventListener('click', () => {
        sliderSwitchDelete();
        for (const teammate of teammateList) {
          if (teammate.classList.contains('about-team__slide_active')) {
            teammate.classList.remove('about-team__slide_active');
          }
          if (teammate.dataset.teammates === teammateSwitch.dataset.section) {
            teammate.classList.add('about-team__slide_active');
            teammateSwitch.querySelector('.about-team__label').classList.add('about-team__label_active');
          }
        }
        teammateBlockResize();
      });
    }
    teammateBlockResize();
  }, timeout);
};

window.addEventListener('resize', teammateBlockResize);
document.addEventListener('DOMContentLoaded', postponement);