document.addEventListener('click', function (e) {
    if (e.target.classList.contains('about-team__detail')) {
        const data = JSON.parse(e.target.dataset.data);
        new TeamDialog(data);

        console.log(true)
    }

})
let dialog;

class TeamDialog {
    createDialog(data) {
        const dialog = document.createElement('dialog');
        dialog.classList.add('teamDialog');
        document.body.append(dialog);
        this.close = document.createElement('div');
        this.close.classList.add('teamDialog__close');
        this.close.innerHTML = `
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.41406 4.33594L4.33594 5.41406L10.9219 12L4.33594 18.5859L5.41406 19.6641L12 13.0781L18.5859 19.6641L19.6641 18.5859L13.0781 12L19.6641 5.41406L18.5859 4.33594L12 10.9219L5.41406 4.33594Z" fill="#212121"/>
            </svg>`;
        this.closeModalHendler = this.closeModal.bind(this)
        let html = '';
        html+= `<div class="teamDialog__person teamDialogPerson">
                    <img  class="teamDialogPerson__image" src="${data.avatar}" alt="">
                    <div class="teamDialogPerson__caption">
                        <div class="teamDialogPerson__name">${data.name}</div>
                        <div class="teamDialogPerson__position">${data.profession}</div>
                    </div>
                </div>`;

                if (data.description){

                    html+= `<div class="teamDialog__aboutPerson teamDialogAboutPerson">
                            <div class="teamDialogAboutPerson__title">О тренере</div>
                            <div class="teamDialogAboutPerson__description">${data.description}</div>
                        </div>`;
                }
              if (data.phone||data.mail||data.prices){
                  html+= `<div class="teamDialog__personInfo teamDialogPersonInfo ">`;
                  if (data.phone||data.mail){
                      html+= `
                    <div class="teamDialogPersonInfo__data">
                        <div class="teamDialogPersonInfo__title">Контакты</div>
                        <div class="teamDialogPersonInfo__content">`
                           if (data.phone){
                               html+= `
                             Телефон: <a href="tel:${data.phone}">${data.phone}</a>`
                           }
                           if (data.mail) {
                               html+= `
                             Email:<a href="mailto:example@example.com">${data.mail}</a>`
                           }
                      html+= `</div>`
                      html+= `</div>`
                  }
                  if (data.prices){
                      html+= `<div class="teamDialogPersonInfo__data">
                                        <div class="teamDialogPersonInfo__title">Услуги и цены</div>
                                        <div class="teamDialogPersonInfo__content">${data.prices}</div>
                                     </div>`
                  }
                  html+= `</div>`;
              }
              dialog.innerHTML = html
        dialog.appendChild(this.close);
        this.colseHandler = this.closeDialog.bind(this)
        dialog.addEventListener('close', this.colseHandler)
        this.close.addEventListener('click', this.closeModalHendler)
        return dialog

    }
    backdropClick(){

        this.dialog.close()
    }

    closeModal() {
        this.dialog.close()
    }

    closeDialog() {
        this.dialog.removeEventListener('click', this.colseHandler)
        this.close.removeEventListener('click', this.closeModalHendler)
        this.dialog.remove()
        delete this
    }

    constructor(data) {
        this.dialog = this.createDialog(data)
        this.dialog.showModal()
    }
}